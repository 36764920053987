const distances = {
  pageMarginBig: 30,
  pageMarginSmall: 10,
  gap: 20,
  gapSmall: 10,
  space: 80,
  radiusS: 4,
  radiusXl: 16,
};

export default distances;
