const colors = {
  dark: "#171717",
  white: "#F4F4F4",
  yellow: "#FAE342",
  orange: "#E95226",
  pink: "#EB6DAA",
  overlay: "rgba(0, 0, 0, 0.4)",
};

export default colors;
