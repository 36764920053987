import styled from "styled-components";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const MainWrapper = styled.div`
  padding: 0;
  width: 80%;
  margin-left: 20%;
  box-sizing: border-box;

  position: relative;
  height: 100%;
  @media (max-width: ${mediaQuery.tablet}px) {

    width: 100%;
    margin-left: 0;
  }
`;

export default MainWrapper;
