import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import Lottie from "lottie-react";

import LogoBar from "src/assets/images/LogoBar.inline.svg";
import BrandPatern from "src/assets/images/BrandBar.inline.svg";
import colors from "../../assets/styles/colors";

import animation01 from "src/assets/animations/01.json";
import { Link } from "gatsby";

const PATTERN_COUNT = 10;
const SCROLL_MULTIPLIER = 1.1;

const Wrapper = styled.aside`
  width: 20%;
  display: block;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;

  @media (max-width: ${mediaQuery.tablet}px) {
    position: fixed;
    left: 0;
    width: 100vw;
    height: 90rem;
    top: 100vh;
    transform: translateY(-100%);
    z-index: 99999;
  }
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transform: translateY(-${(props) => props.scrollPosition}px);
  transition: transform 0.1s linear;
  will-change: transform;

  @media (max-width: ${mediaQuery.tablet}px) {
    transform: translateX(-${(props) => props.scrollPosition}px) rotate(-90deg);
    transform-origin: 45rem 45rem;
  }
`;

const StyledLogoBar = styled(Link)`
  width: 100%;
  background-color: ${colors.dark};

  @media (max-width: ${mediaQuery.tablet}px) {
    display: none;
  }
`;

const StyledBrandPatern = styled(BrandPatern)`
  @media (max-width: ${mediaQuery.tablet}px) {
    width: 90rem;
  }
`;

const BrandBar = ({ locale }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        setScrollPosition(window.scrollY * SCROLL_MULTIPLIER);
      });
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Add new state for Lottie animation control
  const [isLogoAnimating, setIsLogoAnimating] = React.useState(false);

  // Add ref for Lottie animation
  const lottieRef = React.useRef();

  return (
    <Wrapper>
      <BarWrapper scrollPosition={scrollPosition}>
        <StyledLogoBar to={`${locale === "pl" ? "/pl/home" : "/"}`}>
          <Lottie
            lottieRef={lottieRef}
            animationData={animation01}
            loop={true}
            autoplay={false}
            onMouseEnter={() => {
              lottieRef.current?.goToAndPlay(0);
            }}
            onMouseLeave={() => {
              lottieRef.current?.stop();
            }}
          />
        </StyledLogoBar>
        {Array.from({ length: PATTERN_COUNT }, (_, index) => (
          <StyledBrandPatern key={`pattern-${index}`} />
        ))}
      </BarWrapper>
    </Wrapper>
  );
};

export default memo(BrandBar);
