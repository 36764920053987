import React, { useRef } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import distances from "src/assets/styles/distances.js";

import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ color }) => color};

  height: 100vh;
  width: 100vw;
  z-index: 99999999;
  opacity: 0;
  visibility: hidden;

  transition: opacity 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.open {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.8s cubic-bezier(0.65, 0, 0.35, 1);
  }
  iframe {
    width: 80% !important;
    margin: 0 auto;
    display: block;
    @media (max-width: ${mediaQuery.tablet}px) {
      width: 100% !important;
    }
  }
`;

const Close = styled.button`
  position: fixed;
  top: 0rem;
  right: 0rem;
  background-color: ${colors.pink};
  z-index: 99999999999;
  cursor: pointer;
  width: 90rem;
  height: 90rem;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40rem;
    height: 1rem;
    background-color: white;
    transition: transform 0.3s ease;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover::before {
    transform: translate(-50%, -50%) rotate(135deg);
  }

  &:hover::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const PopUpPlayer = ({ open, closeFn, vidUrl, color }) => {
  const vidPlayer = useRef();
  const handlePause = () => {
    vidPlayer.current.seekTo(0);
  };
  return (
    <Wrapper className={open && "open"} color={color}>
      <ReactPlayer
        ref={vidPlayer}
        playing={open}
        controls={true}
        url={vidUrl}
        width="100%"
        height="100%"
        playbackRate={open ? 1 : 0} // This will stop playback when closed
        onPause={handlePause}
      />

      <Close onClick={closeFn}> </Close>
    </Wrapper>
  );
};

export default PopUpPlayer;
