import React from "react";
import { Consumer, ContextProviderComponent } from "src/layouts/Context.js";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import GlobalStyle from "src/assets/styles/globalStyles.js";

// import Cookies from "src/components/global/Cookies.js";

//assets
import OTOLogo from "src/assets/images/OTO-logo.inline.svg";

//styles
import mediaQuery from "src/assets/styles/mediaQuery.js";

//components

import BrandBar from "src/components/global/BrandBar.js";
import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import PopUpPlayer from "src/components/global/PopUpPlayer.js";
const Wrapper = styled.main``;

const OttoWrapper = styled.a`
  width: 100%;
  height: 32rem;
  position: relative;
  display: block;

  div {
    width: 100%;
    height: 32rem;
    position: absolute !important;
    left: 0;
    top: 0;
  }
  img {
    position: absolute !important;
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: ${mediaQuery.tablet}px) {
    margin-bottom: 90rem;
  }
`;

const Index = ({ data, children, pageContext }) => {
  const { locale } = pageContext;
  return (
    <ContextProviderComponent>
      <Consumer>
        {({ data, set }) => (
          <>
            <GlobalStyle />
            <Wrapper>
              <BrandBar locale={locale} />
              <MainWrapper>{children}</MainWrapper>
              <OttoWrapper href="https://otofamily.com/" target="_blank">
                <StaticImage
                  src="../assets/images/OTO-bg.png"
                  alt="OTO background"
                  placeholder="blurred"
                />
                <OTOLogo />
              </OttoWrapper>
            </Wrapper>
            <PopUpPlayer
              open={data.videoState.isOpen}
              closeFn={() =>
                set({ videoState: { isOpen: false, videoUrl: "", color: "" } })
              }
              vidUrl={data.videoState.videoUrl}
              color={data.videoState.color}
            />
          </>
        )}
      </Consumer>
    </ContextProviderComponent>
  );
};

export default Index;
