const typographySizes = {
  xs: 10,
  xsh: 1.4,
  s: 12,
  sh: 1.4,
  sm: 14,
  smh: 1.4,
  m: 28,
  mh: 1,
  l: 40,
  lh: 0.8,
  xl: 56,
  xlh: 0.9,
};

export default typographySizes;
